.card-user-info {
  background-image: var(--Paper-overlay) !important;
  .wallet-card {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    aspect-ratio: 678 / 200;
    background-image: url("~@/assets/images/wallet-card.png");
  }

  .wallet-bind-card {
    .label {
      &::after {
        content: ":";
        padding-right: var(--mui-spacing);
      }
    }

    .value {
      padding-right: var(--mui-spacing);
    }
  }
}
