.bind-invite-frament {
    background-image: url("~@/assets/images/digitalHuman/unbind-bg.png");
    background-color: var(--mui-palette-common-background);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: left top;
    background-size: cover;
    overflow-y: scroll;
    height: 100%;
    width: 100%;
}