body {
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    overflow: hidden;
    flex-direction: column;

    @media (max-width: 1050px) {
        & {
            max-width: none;
        }
    }
}

@media screen and (min-width: 1050px) {
    html{
        overflow: hidden;
        background-color: #042245;
    }
    body {
        max-width:30%;
        transform: perspective(0);
        margin: 0 auto !important;
    }
    #root{
        position: relative;
        //transform: perspective(0);
    }
}

::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem;
}

::-webkit-scrollbar-track {
    background-color: var(--mui-palette-background-default);
}

::-webkit-scrollbar-thumb {
    background-color: var(--mui-palette-primary-main);
    width: 0.1rem;
}