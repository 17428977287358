.fragment-package {
    .area-info-container {
        position: relative;

        .card-item-container {
            .card-item {
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                background-image: url("~@/assets/images/digitalHuman/col1.png");
            }

            .card-num-1 {
                aspect-ratio: 1 / 1;
                background-image: url("~@/assets/images/digitalHuman/col1.png");
            }

            .card-num-2 {
                aspect-ratio: 1 / 1;
                background-image: url("~@/assets/images/digitalHuman/col2.png");
            }

            .card-num-3 {
                aspect-ratio: 1 / 1;
                background-image: url("~@/assets/images/digitalHuman/col3.png");
            }

            .card-num-4 {
                aspect-ratio: 1 / 1;
                background-image: url("~@/assets/images/digitalHuman/col4.png");
            }

            .card-size-full {
                aspect-ratio: 677 / 200;
                background-image: url("~@/assets/images/digitalHuman/col5.png");
            }
        }
    }
}