.page-announcement-list {
	.list-item-card {
	  .MuiBox-root {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
  
		span {
		  line-height: 2;
		}
	  }
	}
  }
  