.layout-home {
  overflow-y: hidden;
  .page-content {
    overflow-y: auto;
  }
}

.navigation {
  border-top: 0.1rem solid #020c18;
}

.MuiBottomNavigation-root {
  .nav-icon {
    width: 1.4rem;
    height: 1.4rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    &.icon-home {
      background-image: url("~@/assets/images/bottomNav/icon-home-normal.png");
    }

    &.icon-bank {
      background-image: url("~@/assets/images/bottomNav/icon-bank-normal.png");
    }

    &.icon-rwa {
      background-image: url("~@/assets/images/bottomNav/icon-rwa-normal.png");
    }

    &.icon-game {
      background-image: url("~@/assets/images/bottomNav/icon-game-normal.png");
    }

    &.icon-bot {
      background-image: url("~@/assets/images/bottomNav/icon-bot-normal.png");
    }

    &.icon-mine {
      background-image: url("~@/assets/images/bottomNav/icon-mine-normal.png");
    }
  }

  .Mui-selected {
    .nav-icon {
      &.icon-home {
        background-image: url("~@/assets/images/bottomNav/icon-home-selected.png");
      }

      &.icon-bank {
        background-image: url("~@/assets/images/bottomNav/icon-bank-selected.png");
      }

      &.icon-rwa {
        background-image: url("~@/assets/images/bottomNav/icon-rwa-selected.png");
      }

      &.icon-game {
        background-image: url("~@/assets/images/bottomNav/icon-game-selected.png");
      }

      &.icon-bot {
        background-image: url("~@/assets/images/bottomNav/icon-bot-selected.png");
      }

      &.icon-mine {
        background-image: url("~@/assets/images/bottomNav/icon-mine-selected.png");
      }
    }
  }
}
