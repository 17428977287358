.icon-menu-andybot {
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/menu/andybot.png");
}

.icon-menu-announcement {
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/menu/announcement.png");
}

.icon-menu-bank {
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/menu/bank.png");
}

.icon-menu-customer {
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/menu/customer.png");
}

.icon-menu-rwa {
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/menu/RWA.png");
}

.icon-menu-wallet {
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/menu/wallet.png");
}

.icon-menu-wsk {
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/menu/WSK.png");
}

.icon-menu-zootopia {
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/menu/ZOOTOPIA.png");
}

.icon-menu-package {
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/menu/package.png");
}

.icon-menu-income {
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/menu/income.png");
}

.icon-menu-bonus-report {
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/menu/bonus_report.png");
}

.icon-menu-community {
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/menu/community.png");
}

.icon-menu-invite {
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/menu/invite.png");
}

.icon-menu-rwa-profile{
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/menu/krypton_personal.png");
}
.menu-drawer {
    .MuiModal-backdrop {
    }
    .MuiDrawer-paper {
        transition: all 225ms cubic-bezier(0, 0, 0.2, 1) !important;
        width: calc(100% * 0.72);
    }
}