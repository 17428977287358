.trade-filter-dialog {
    .form-item {
        .form-item-key {
            margin-bottom: 0.56rem;
        }

        .form-item-value {
            width: 100%;
            margin-bottom: 0.56rem;
        }
    }

}