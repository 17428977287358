.app {
  height: 100vh;
  user-select: none;
}

.layout {
  height: 100%;
}

.icon-arrow-right {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  aspect-ratio: 34 / 25;
  background-image: url("~@/assets/images/icon-arrow-right.png");
}
.icon-more {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    aspect-ratio: 34 / 25;
    background-image: url("~@/assets/images/icon-more.png");
}
.icon-dot-arrow {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  aspect-ratio: 34 / 25;
  background-image: url("~@/assets/images/icon-dot-arrow.png");
}
.icon-eye-brief {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/eye-brief.png");
}

.icon-eye-detail {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/eye-detail.png");
}

.icon-action-charge {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/action/action_charge.png");
}

.icon-action-withdraw {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/action/action_withdraw.png");
}

.icon-action-swap {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/action/action_swap.png");
}
.icon-action-setting {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/action/action_setting.png");
}
.icon-action-add{
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/action/action_add.png");
}

.icon-action-transfer {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/action/action_transfer.png");
}
.icon-action-cdk {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/action/action_cdk.png");
}
.icon-action-copy {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/action/action_copy.png");
}
.icon-action-success {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/action/action_success.png");
}
.icon-action-copy-normal {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/action/action_copy_normal.png");
}
.icon-action-success-normal {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/action/action_success_normal.png");
}
.icon-action-send {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/action/action_send.png");
}
.icon-action-image {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/action/action_image.png");
}

.icon-select-date {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/icon-select-date.png");
}

.icon-empty {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/icon-empty.png");
}

.icon-eye {
  background-size: 100% 80%;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/icon-eye.png");
}
.icon-ellipse {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/icon-ellipse.png");
}
.icon-history-record {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/icon-history-record.png");
}
.icon-dot-error {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/icon-dot-error.png");
}
.icon-dot-success {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/icon-dot-success.png");
}
.icon-search{
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/icon-search.png");
}
.icon-minus {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/icon-minus.png");
}
.divider-subtile {
  //background-size: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/divider-bg.png");
}

.icon-glass{
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/icon-glass.png");
}
.icon-glass-grey{
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/icon-glass-grey.png");
}
.icon-arrow {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/icon-arrow.png");
}
.icon-usd-account {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/icon-usd-account.png");
}

