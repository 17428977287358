.tab-dashboard {
  .part-1 {
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .logo {
    height: 4rem;
  }

  .item-link {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    //border-radius: 16px;
  }

  .item-1 {
    background-image: url("~@/assets/images/dashboard/bank.png");
  }

  .item-2 {
    background-image: url("~@/assets/images/dashboard/wsk.png");
  }

  .item-3 {
    background-image: url("~@/assets/images/dashboard/rwa.png");
  }

  .item-4 {
    background-image: url("~@/assets/images/dashboard/andybot.png");
  }

  .item-5 {
    background-image: url("~@/assets/images/dashboard/zootopia.png");
  }
}
