.tab-burning-profile {
    .wallet-card {
        aspect-ratio: unset;
        background-image: none;
    }

    // .card-my-portfolio {
    //     .portfolio-item-list {
    //         .portfolio-item {
    //             position: relative;

    //             &:nth-child(even) {
    //                 &::before {
    //                     content: '';
    //                     background-color: var(--mui-palette-divider);
    //                     width: 2px;
    //                     height: 100%;
    //                     position: absolute;
    //                     left: -0.6rem;
    //                 }
    //             }
    //         }
    //     }
    // }
}