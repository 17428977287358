.chat-item {
  position: relative;
  // &-arrow-right {
  //   &::before {
  //     content: "";
  //     position: absolute;
  //     width: 0;
  //     height: 0.8rem;
  //     border-right: 1rem solid #e4c072;
  //     border-top-right-radius: 2rem 2rem;
  //     background-color: #e4c072;
  //     right: -14px;
  //     top: 50%;
  //     transform: translate(0, -50%);
  //   }
  //   // &::after {
  //   //   content: "";
  //   //   position: absolute;
  //   //   width: 15px;
  //   //   height: 12px;
  //   //   background-color: #000;
  //   //   border-top-right-radius: 25px 25px;
  //   //   right: -15px;
  //   //   bottom: 25px;
  //   // }
  // }
  // &-arrow-left {
  // &::before {
  // 	content: "";
  // 	position: absolute;
  // 	width: 0;
  // 	height: 1rem;
  // 	border-right: 15px solid #1A2027;
  // 	border-top-left-radius: 24px 19px;
  // 	background-color: #1A2027;
  // 	left: -14px;
  // 	top: 10px;
  //   }
  //   &::after {
  // 	content: "";
  // 	position: absolute;
  // 	width: 15px;
  // 	height: 13px;
  // 	background-color: #000;
  // 	border-top-left-radius: 35px 42px;
  // 	left: -14px;
  // 	bottom: 2px;
  //   }
  // }
}
